import { useEffect, useState, useMemo } from 'react'

function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false)

  const observer = useMemo(() => {
    if (typeof window !== 'undefined') {
      return new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting))
    }
  }, [])

  useEffect(() => {
    observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
  }, [ref, observer])

  return isIntersecting
}

export default useIsInViewport
