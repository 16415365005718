import axios from 'axios'

export const getModalData = async (url) => {
  try {
    let modalId = url

    if (url?.startsWith('#')) {
      modalId = url?.replace('#', '')
    }

    const { data } = await axios.post(
      `${
        process.env.NEXT_PUBLIC_API_BASE_URL || process.env.NEXT_PUBLIC_BASE_URL
      }/api/contentful/modal/get-modal`,
      {
        modalId,
      }
    )

    return data
  } catch (error) {
    console.log('getModalData error:', error)
  }
}
